body {
    margin: 0 !important;
    font-family: "Roboto" !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }
  a {
    text-decoration: none !important;
    outline: none !important;
  }
  a:hover {
    text-decoration: none !important;
  }

  @font-face {
    font-family: 'Dolce Vita';
    src: url('./assets/fonts/DolceVitaLight.eot');
    src: url('./assets/fonts/DolceVitaLight.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DolceVitaLight.woff2') format('woff2'),
        url('./assets/fonts/DolceVitaLight.woff') format('woff'),
        url('./assets/fonts/DolceVitaLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Dolce Vita';
    src: url('./assets/fonts/DolceVita.eot');
    src: url('./assets/fonts/DolceVita.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DolceVita.woff2') format('woff2'),
        url('./assets/fonts/DolceVita.woff') format('woff'),
        url('./assets/fonts/DolceVita.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dolce Vita';
    src: url('./assets/fonts/DolceVitaHeavy-Bold.eot');
    src: url('./assets/fonts/DolceVitaHeavy-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DolceVitaHeavy-Bold.woff2') format('woff2'),
        url('./assets/fonts/DolceVitaHeavy-Bold.woff') format('woff'),
        url('./assets/fonts/DolceVitaHeavy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Silver Charm';
    src: url('./assets/fonts/SilverCharm.eot');
    src: url('./assets/fonts/SilverCharm.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SilverCharm.woff2') format('woff2'),
        url('./assets/fonts/SilverCharm.woff') format('woff'),
        url('./assets/fonts/SilverCharm.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
